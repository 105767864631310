import { Injectable } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({ providedIn: 'root' })
export class IconService {


    constructor(private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer) {

    }
    connectIcons() {
        this.matIconRegistry.addSvgIcon(
            'adminAddPhoto',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-add-photo.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminDate',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-date.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminWarnCircle',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-warn-circle.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminAttach',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-attach.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminCaution',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-caution.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminDelete',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-delete.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminDeleteGray',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/delete.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'ban',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/ban.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminDisabled',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-disabled.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminOnHold',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-on-hold.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'infoBlue',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/info-3.svg'),
        );        
        this.matIconRegistry.addSvgIcon(
            'info',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/info.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'check',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/check.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminPassword',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-password.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminEdit',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-edit.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'icon-custom',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-shift-custom.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'icon-dayshift',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-shift-day.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'icon-nightshift',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-shift-night.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'icon-noonshift',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-shift-noon.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminCalendar',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-calendar.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'export',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/export.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'exportBlue',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/export-blue.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'exportGray',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/export-gray.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'documentText',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/document-text.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'exportUser',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/export-user.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'exportCalendar',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/export-calendar.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'exportOffice',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/export-office.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminRedit',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-redit.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminClock',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-clock.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'rectangle',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/rectangle.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminXCircle',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-x-circle.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminGreyX',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-grey-x.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'rateHistory',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/rate-history.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'podIllustration',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/pod-illustration.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'adminTrusted',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/admin-trusted.svg'),
        );
        this.matIconRegistry.addSvgIcon(
            'calendarSearch',
            this.domSanitizer.bypassSecurityTrustResourceUrl('assets/custom-icons/calendar-search.svg'),
        );
    }
}
