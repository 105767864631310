import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject, Subscription, throwError, zip } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class MessagesService
{

    
    constructor(app: AppService, private snackBar: MatSnackBar,)
    {
    }

    private serverError(error: HttpErrorResponse)
    {
        try
        {
            const message = error.error.message;
            this.snackBar.open(message, 'Dismiss', { duration: 6000, panelClass: 'snackbar-error' });
        }
        catch(e) { console.log('Server Error: ', e); }
    }
    pipeHttpError<T>() : (source: Observable<T>) => Observable<T>
    {
        return catchError(e =>
        {
            this.serverError(e);
            return throwError(e);
        });
    }
}
