import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NoAuthGuard, AdminGuard, AuthGuard } from './guards';

const routes: Routes = [

    {
        path: '',
        children: [
            {
                path: 'auth',
                canActivate: [NoAuthGuard],
                loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
            },
            {
                path: 'admin',
                canActivate: [AuthGuard, AdminGuard],
                loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'auth'
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
